<template>
  <div>
    <v-container>
      <v-row
          align="center"
          justify="end"
      >
        <v-col
            cols="12"
        >
          <v-btn class="add-new-btn mb-5" block rounded color="primary" small @click="redirectToLineNotify">
            前往綁定
          </v-btn>
          <v-btn class="add-new-btn mb-5" block rounded color="primary" small @click="redirectToUnbindLineNotify">
            取消綁定
          </v-btn>
          <div style="text-align: center;">
            <div v-if="isBoundSuccessfully" class="text-h6">
              綁定成功
            </div>
          </div>
          <template>
            <div>
              <div v-for="item in items" :key="item.id" class="card">
                <div class="card-header">
                  {{ item.display_name }}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    通知類型: {{ item.target_type === 'USER' ? '個人通知' : '群組通知' }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import storage from 'libs/storage/localStorage.js'

export default {
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const providerId = urlParams.get('state');
    const res = await this.$api.collection.providerApi.getLineNotifyClientId();
    this.lineNotifyClientId = res['lineNotifyClientId'];

    if (code && providerId) {
      console.log('start line relation');
      const res = await this.$api.collection.providerApi.relationLineNotifyAccessToken(providerId, code);
      console.log(res);
      this.isBoundSuccessfully = true;
    }
    const fromStorageProviderId = storage().get('chooseProviderId');
    const response = await this.$api.collection.providerApi.getLineNotifyListByProviderId(fromStorageProviderId);
    this.items = response.data;
  },
  methods: {
    redirectToLineNotify() {
      this.$root.gaLogEvent('供應商_點擊_綁定Notify')
      const fromStorageProviderId = storage().get('chooseProviderId');
      const currentURL = window.location.origin + window.location.pathname;
      window.location.href = `https://notify-bot.line.me/oauth/authorize?response_type=code&redirect_uri=${encodeURIComponent(currentURL)}&scope=notify&state=${fromStorageProviderId}&client_id=${this.lineNotifyClientId}`;
    },
    redirectToUnbindLineNotify() {
      this.$root.gaLogEvent('供應商_點擊_解除綁定Notify')
      window.location.href = `https://notify-bot.line.me/my/`;
    }
  },
  data() {
    return {
      lineNotifyClientId: null,
      isBoundSuccessfully: false,
      items: [],
    };
  }
};
</script>
<style>
.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-header {
  font-weight: bold;
}

.card-body {
  margin-top: 10px;
}

.card-text {
  margin-bottom: 10px;
}

.v-btn:not(.v-btn--round).v-size--small {
  height: 36px;
}
</style>
